import { Link } from "gatsby";
import React, { useState } from "react";
import ScrollContainer from "react-indiana-drag-scroll";

import BackgroundMenu from "../../images/VerticalMenuBg.png";
import Person from "./Person";

import Cameron from "../../images/CameronPhoto.png";
import MDowd from "../../images/MDowd_Photo.png";
import Michal from "../../images/Michal_Photo.png";
import Owen from "../../images/Owen_Photo.png";
import Swapnil from "../../images/Swapnil_Photo.png";

const HorizontalScrolMenu = () => {
  const [toggleState, setToggleState] = useState(1);

  const toggleTab = index => {
    setToggleState(index);
  };

  return (
    <div>
      <ScrollContainer className="w-full scroll-container h-32 flex justify-start md:justify-center items-center flex-row absolute mt-2">
        <div className="flex flex-row items-center justify-center p-2">
          <button
            className={
              toggleState === 1
                ? "font-bold py-2 px-4 border-2 border-white w-[100%] flex flex-row bg-transparent bg-blur text-white"
                : " py-2 px-4 border-2 border-white w-[100%] flex flex-row bg-slate-500 text-white font-bold opacity-80"
            }
            onClick={() => toggleTab(1)}
          >
            <p>All</p>
          </button>
        </div>
        <div className="flex flex-row items-center justify-center p-2">
          <button
            className={
              toggleState === 2
                ? "font-bold py-2 px-4 border-2 border-white w-[100%] flex flex-row bg-transparent bg-blur text-white"
                : " py-2 px-4 border-2 border-white w-[100%] flex flex-row bg-slate-500 text-white font-bold opacity-80"
            }
            onClick={() => toggleTab(2)}
          >
            <p>Executive</p> <p>Team</p>
          </button>
        </div>
        <div className="flex flex-row items-center justify-center p-2">
          <button
            className={
              toggleState === 3
                ? "font-bold py-2 px-4 border-2 border-white w-[100%] flex flex-row bg-transparent bg-blur text-white"
                : " py-2 px-4 border-2 border-white w-[100%] flex flex-row bg-slate-500 text-white font-bold opacity-80"
            }
            onClick={() => toggleTab(3)}
          >
            <p>Development</p> <p>Team</p>
          </button>
        </div>
        <div className="flex flex-row items-center justify-center p-2">
          <button
            className={
              toggleState === 4
                ? "font-bold py-2 px-4 border-2 border-white w-[100%] flex flex-row bg-transparent bg-blur text-white"
                : " py-2 px-4 border-2 border-white w-[100%] flex flex-row bg-slate-500 text-white font-bold opacity-80"
            }
            onClick={() => toggleTab(4)}
          >
            Marketing
          </button>
        </div>
        <div className="flex flex-row items-center justify-center p-2">
          <button
            className={
              toggleState === 5
                ? "font-bold py-2 px-4 border-2 border-white w-[100%] flex flex-row bg-transparent bg-blur text-white"
                : " py-2 px-4 border-2 border-white w-[100%] flex flex-row bg-slate-500 text-white font-bold opacity-80"
            }
            onClick={() => toggleTab(5)}
          >
            <p>Advisors</p>
          </button>
        </div>
      </ScrollContainer>
      <div className="">
        <img src={BackgroundMenu} className="h-36 w-screen" />
      </div>
      <article className="flex items-center justify-center">
        <div
          className={
            toggleState === 1
              ? "flex flex-col items-center justify-center w-[100%]"
              : "hidden"
          }
        >
          <div className="flex flex-col">
            <Link to="/teammateMatthew">
              <Person
                IMGSrc={MDowd}
                Name="Matthew Dowd"
                Description="CEO and Founder"
              />
            </Link>
            <Link to="/teammateMichal">
              <Person
                IMGSrc={Michal}
                Name="Michal Jaroň"
                Description="Developer"
              />
            </Link>
            <Link to="/teammateOwen">
              <Person
                IMGSrc={Owen}
                Name="Owen Meyers"
                Description="Developer"
              />
            </Link>
          </div>
          <div className="flex flex-col">
            <Link to="/teammateSwapnil">
              <Person
                IMGSrc={Swapnil}
                Name="Swapnil Narola"
                Description="Developer"
              />
            </Link>
            <Link to="/teammateCameron">
              <Person
                IMGSrc={Cameron}
                Name="Cameron Andersen"
                Description="Project Leader"
              />
            </Link>
          </div>
        </div>
        <div
          className={
            toggleState === 2
              ? "flex flex-col items-center justify-center w-[100%]"
              : "hidden"
          }
        >
          <Link to="/teammateMatthew">
            <Person
              IMGSrc={MDowd}
              Name="Matthew Dowd"
              Description="CEO and Founder"
            />
          </Link>
        </div>
        <div
          className={
            toggleState === 3
              ? "flex flex-col items-center justify-center w-[100%]"
              : "hidden"
          }
        >
          <Link to="/teammateMichal">
            <Person
              IMGSrc={Michal}
              Name="Michal Jaroň"
              Description="Developer"
            />
          </Link>
          <Link to="/teammateOwen">
            <Person
              IMGSrc={Owen}
              Name="Owen Meyers"
              Description="Developer"
            />
          </Link>
          <Link to="/teammateSwapnil">
            <Person
              IMGSrc={Swapnil}
              Name="Swapnil Narola"
              Description="Developer"
            />
          </Link>
        </div>
        <div
          className={
            toggleState === 4
              ? "flex flex-col items-center justify-center w-[100%]"
              : "hidden"
          }
        >
          <Link to="/teammateCameron">
            <Person
              IMGSrc={Cameron}
              Name="Cameron Andersen"
              Description="Project Leader"
            />
          </Link>
        </div>
        <div
          className={
            toggleState === 5
              ? "flex flex-col items-center justify-center w-[100%]"
              : "hidden"
          }
        >
          <Person
            IMGSrc=""
            Name=""
            Description="Exciting announcements coming soon..."
          />
        </div>
      </article >
    </div >
  );
};

export default HorizontalScrolMenu;
