import { Link } from "gatsby"
import React, { useState } from "react"
import Person from "./Person"


import Cameron from "../../images/CameronPhoto.png"
import MDowd from "../../images/MDowd_Photo.png"
import Michal from "../../images/Michal_Photo.png"
import Owen from "../../images/Owen_Photo.png"
import Swapnil from "../../images/Swapnil_Photo.png"

const Tabs = () => {
  const [toggleState, setToggleState] = useState(1)

  const toggleTab = index => {
    setToggleState(index)
  }

  return (
    <div>
      <nav className="flex flex-row w-[100%] justify-center">
        <div className="w-56 flex flex-row items-center justify-center border-b-2">
          <button
            className={
              toggleState === 1
                ? "font-bold py-4 border-b-2 border-black w-[100%]"
                : "font-regular py-4"
            }
            onClick={() => toggleTab(1)}
          >
            All
          </button>
        </div>
        <div className="w-56 flex flex-row items-center justify-center border-b-2">
          <button
            className={
              toggleState === 2
                ? "font-bold py-4 border-b-2 border-black w-[100%]"
                : "font-regular py-4"
            }
            onClick={() => toggleTab(2)}
          >
            Executive Team
          </button>
        </div>
        <div className="w-56 flex items-center justify-center border-b-2">
          <button
            className={
              toggleState === 3
                ? "font-bold py-4 border-b-2 border-black w-[100%]"
                : "font-regular py-4"
            }
            onClick={() => toggleTab(3)}
          >
            Development Team
          </button>
        </div>
        <div className="w-52 flex items-center justify-center border-b-2">
          <button
            className={
              toggleState === 4
                ? "font-bold py-4 border-b-2 border-black w-[100%]"
                : "font-regular py-4"
            }
            onClick={() => toggleTab(4)}
          >
            Marketing
          </button>
        </div>
        <div className="w-56 flex items-center justify-center border-b-2">
          <button
            className={
              toggleState === 5
                ? "font-bold py-4 border-b-2 border-black w-[100%]"
                : "font-regular py-4"
            }
            onClick={() => toggleTab(5)}
          >
            Advisors
          </button>
        </div>
      </nav>
      <article className="flex items-center justify-center">
        <div
          className={
            toggleState === 1
              ? "flex flex-col items-center justify-center w-[100%] flex-wrap"
              : "hidden"
          }
        >
          <div className="flex flex-col items-center justify-center">
            <div className="flex flex-row">
              <Link to="/teammateMatthew">
                <Person
                  IMGSrc={MDowd}
                  Name="Matthew Dowd"
                  Description="CEO and Founder"
                />
              </Link>
              <Link to="/teammateMichal">
                <Person
                  IMGSrc={Michal}
                  Name="Michal Jaroň"
                  Description="Developer"
                />
              </Link>
              <Link to="/teammateOwen">
                <Person
                  IMGSrc={Owen}
                  Name="Owen Meyers "
                  Description="Developer"
                />
              </Link>
            </div>
            <div className="flex flex-row">
              <Link to="/teammateSwapnil">
                <Person
                  IMGSrc={Swapnil}
                  Name="Swapnil Narola"
                  Description="Developer"
                />
              </Link>
              <Link to="/teammateCameron">
                <Person
                  IMGSrc={Cameron}
                  Name="Cameron Andersen"
                  Description="Project Leader"
                />
              </Link>
            </div>
          </div>
        </div>
        <div
          className={
            toggleState === 2
              ? "flex flex-row items-center justify-center w-[50rem] flex-wrap"
              : "hidden"
          }
        >
          <div className="flex flex-row items-center justify-center">
            <Link to="/teammateMatthew">
              <Person
                IMGSrc={MDowd}
                Name="Matthew Dowd"
                Description="CEO and Founder"
              />
            </Link>
          </div>
        </div>
        <div
          className={
            toggleState === 3
              ? "flex flex-row items-center justify-center w-[70%] flex-wrap"
              : "hidden"
          }
        >
          <div className="flex flex-row items-center justify-center">
            <Link to="/teammateMichal">
              <Person
                IMGSrc={Michal}
                Name="Michal Jaroň"
                Description="Developer"
              />
            </Link>
            <Link to="/teammateOwen">
            <Person
              IMGSrc={Owen}
              Name="Owen Meyers "
              Description="Developer"
            />
            </Link>
            <Link to="/teammateSwapnil">
            <Person
              IMGSrc={Swapnil}
              Name="Swapnil Narola"
              Description="Developer"
            />
            </Link>
          </div>
        </div>
        <div
          className={
            toggleState === 4
              ? "flex flex-row items-center justify-center w-[70%] flex-wrap"
              : "hidden"
          }
        >
          <div className="flex flex-row items-center justify-center">
            <Link to="/teammateCameron">
              <Person
                IMGSrc={Cameron}
                Name="Cameron Andersen"
                Description="Project Leader"
              />
            </Link>
          </div>
        </div>
        <div
          className={
            toggleState === 5
              ? "flex flex-row items-center justify-center w-[70%] flex-wrap"
              : "hidden"
          }
        >
          <div className="flex flex-row items-center justify-center">
            <Person
              IMGSrc=""
              Name=""
              Description="Exciting announcements coming soon..."
            />
          </div>
        </div>
      </article>
    </div>
  )
}

export default Tabs
