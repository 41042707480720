import React from "react"
import Header from "../components/Headers/Header"
import Navbar from "../components/Navbar/Navbar"
import useMediaQuery from "../hooks/useMediaQuery"
import Footer from "../components/Footer/Footer"
import Tabs from "../components/LeadershipMenu/Tabs"
import ContentV6 from "../components/ContentV/ContentV6/ContentV6"
import ContentV6r from "../components/ContentV/ContentV6/ContentV6r"

import Background from "../images/Generalsectionbackground.png"
import BackgroundMobile from "../images/Generalsectionbackground-mobile.png"
import HorizontalScrolMenu from "../components/LeadershipMenu/HorizontalScrollMenu"
import Logo from "../images/LogoFinalWhite.png"

const leadershipPage = () => {
  const isDesktop = useMediaQuery("(min-width: 1400px)")

  return (
    <div>
      <Navbar />
      <Header
        title="leadership"
        background={Background}
        backgroundMobile={BackgroundMobile}
      />
      <article className="flex flex-col">
        <section className="h-auto bg-white flex flex-col items-center pb-14">
          {isDesktop ? <Tabs /> : <HorizontalScrolMenu />}
        </section>
      </article>
      <Footer />
    </div>
  )
}

export default leadershipPage
