import React from "react"

interface PersonProps {
  IMGSrc: string
  Name: string
  Description: string
}

const Person = ({ IMGSrc, Name, Description }: PersonProps) => {
  return (
    <div className="mt-10 flex flex-col items-center p-2">
      <div className="w-[20rem] h-[20rem] from-gray-200 to-white bg-gradient-to-b rounded-tr-[3rem] rounded-bl-[1rem] overflow-hidden">
        <img src={IMGSrc} />
      </div>
      <div className="flex flex-col items-start w-[90%]">
        <h2 className="font-bold mt-3">{Name}</h2>
        <p className="text-slate-500 mt-1">{Description}</p>
      </div>
    </div>
  )
}

export default Person
